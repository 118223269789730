import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { OnChangeValue }  from "react-select";

import Crossword, {
  CrosswordGrid,
  CrosswordProvider,
  CrosswordProviderImperative,
  CrosswordProviderProps,
  DirectionClues,
} from '@jaredreisinger/react-crossword';

import styled from 'styled-components';
// import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { ThreeCircles } from  'react-loader-spinner'

import EKUT_LOGO from './assets/ekut.svg';
import TEXTPLUS_LOGO from './assets/text-plus-logo.png';

interface WNOption {
  readonly value: string;
  readonly label: string;
};

interface GridOption {
  readonly value: number;
  readonly label: string;
};

interface WNLabel {
  readonly value: string;
  readonly across: string;
  readonly down: string;
};

interface ClueInfo {
     dir: string;
     num: string;
}

const exampleClue : ClueInfo = {
    "dir" : "",
    "num" : ""
}

const wn_options : readonly WNOption[]  = [
  { value: 'germanet', label: 'GermaNet' },
  { value: 'dwds', label: 'DWDS' },
  { value: 'pwn',      label: 'Princeton WordNet' },
//  { value: 'mixing',   label: 'GermaNet & Princeton WordNet' },
];

const rover_pwn_options : readonly WNOption[]  = [
  { value: 'germanet', label: 'Gehe zu Rover' },
  { value: 'dwds', label: 'Gehe zu DWDS' },
  { value: 'pwn',      label: 'Gehe zur PWN site'},
//  { value: 'mixing',   label: 'GermaNet & Princeton WordNet' },
];

const grid_options : readonly GridOption[]  = [
  { value: 15, label: 'Grid: 15x15' },
  { value: 21, label: 'Grid: 21x21' },
  { value: 23, label: 'Grid: 23x23' },
  { value: 25, label: 'Grid: 25x25' },
];

const wn_labels : readonly WNLabel[]  = [
  { value: 'dwds', across: 'Waagerecht', down: 'Senkrecht' },
  { value: 'germanet', across: 'Waagerecht', down: 'Senkrecht' },
  { value: 'pwn',      across: 'Across',     down: 'Down' },
//  { value: 'mixing',   across: 'Across - Waagerecht',     down: 'Down - Senkrecht' },
];

var selectedWN   : any = wn_options[0];
var selectedGridSize : any = grid_options[0];
var selectedDirection : any = '';
var selectedNumber    : number = 0;
var roverOrPWN : string = rover_pwn_options[0].label;

var waitPleasePuzzle : any = {
    "across": {
        "2": {
            "clue":"used in polite request",
            "answer":"PLEASE",
            "row":1,
            "col":0
        },
    },
    "down": {
        "1" : {
            "clue":"the act of waiting (remaining inactive in one place while expecting something)",
            "answer":"WAIT",
            "row":0,
            "col":3
        },
    }
}

var data =  {
  "across": {
    "1": {
      "clue":"Frequenzgruppe mit ähnlichen Eigenschaften",
      "answer":"WELLE",
      "row":17,
      "col":16
    },
    "3": {
      "clue":"Unfall/Zusammenstoß, bei dem ein Fahrzeug auf das vorherfahrende auffährt",
      "answer":"AUFFAHRUNFALL",
      "row":0,
      "col":0
    },
    "5": {
      "clue":"Zoologie Tier aus der Familie der Pferde (equidae)",
      "answer":"PFERD",
      "row":7,
      "col":14
    },
    "7": {
      "clue":"als Adverb: doll, gewaltig, mächtig, sehr, außerordentlich",
      "answer":"UNHEIMLICH",
      "row":5,
      "col":11
    },
    "9": {
      "clue":"umgangssprachlich, vulgär: sich selbst befriedigen (meist als Mann), auch im übertragenen Sinne („geistige Onanie“)",
      "answer":"WICHSEN",
      "row":19,
      "col":7
    },
    "11": {
      "clue":"jemand, der sich hinter einem befindet",
      "answer":"HINTERMANN",
      "row":1,
      "col":2
    },
    "13": {
      "clue":"Eine Person oder Gruppe mit negativer Beziehung zu einer anderen",
      "answer":"FEIND",
      "row":11,
      "col":13
    },
    "15": {
      "clue":"Überbegriff für \"auslernen\"",
      "answer":"BEENDIGEN",
      "row":15,
      "col":12
    },
    "17": {
      "clue":"natürlich vorkommende metallhaltige Mineralien und Mineraliengemische, aus denen durch Verhüttung Metalle gewonnen werden",
      "answer":"ERZ",
      "row":14,
      "col":5
    },
    "19": {"clue":"ohne Ausweg", "answer":"AUSWEGLOS", "row":3, "col":0},
    "21": {
      "clue":"umgangssprachlich: lärmen, streiten, Unruhe machen",
      "answer":"KRAKEELEN",
      "row":10,
      "col":12
    },
    "23": {
      "clue":"Antonym für \"Nonkonformismus\"",
      "answer":"KONFORMISMUS",
      "row":8,
      "col":9
    },
    "25": {
      "clue":"Überbegriff für \"Stadtname\"",
      "answer":"NAMEN",
      "row":16,
      "col":6
    },
    "27": {
      "clue":"eine unangenehme oder schwierige Situation hinnehmen und deswegen nicht die Beherrschung verlieren oder zusammenbrechen",
      "answer":"ERTRAGEN",
      "row":13,
      "col":0
    }
  },
  "down": {
    "2": {
      "clue":"Vorrichtung um Hub- und Schubkräfte zu übertragen",
      "answer":"GESTAENGE",
      "row":6,
      "col":5
    },
    "4": {
      "clue":"Insektenkunde: Mitglied der Spanner, einer der größten Familien der Schmetterlinge mit ca. 26.000 Arten",
      "answer":"SPANNER",
      "row":12,
      "col":20
    },
    "6": {
      "clue":"Überbegriff für \"Ruf\"",
      "answer":"SCHREI",
      "row":0,
      "col":15
    },
    "8": {
      "clue":"die  beim Sitzen durch Unterleib und Oberschenkel gebildete Körperpartie",
      "answer":"SCHOSS",
      "row":3,
      "col":20
    },
    "10": {
      "clue":"mit den Lippen eine andere Person oder einen Gegenstand berühren, zum Zeichen der Liebe oder Verehrung; einen Kuss geben",
      "answer":"KUESSEN",
      "row":13,
      "col":13
    },
    "12": {
      "clue":"Willen dazu, Frieden zu schließen und bewahren",
      "answer":"FRIEDFERTIGKEIT",
      "row":6,
      "col":1
    },
    "14": {
      "clue":"Anstellung, berufliche Stellung",
      "answer":"POSITION",
      "row":7,
      "col":10
    },
    "16": {
      "clue":"Antonym für \"unangekündigt\"",
      "answer":"ANGEKUENDIGT",
      "row":0,
      "col":4
    },
    "18": {
      "clue":"etwas garantieren, gewährleisten (meist durch bestimmte Vorkehrungen)",
      "answer":"SICHERN",
      "row":3,
      "col":2
    },
    "20": {
      "clue":"ähnlich wie Draht, drahtartig",
      "answer":"DRAHTIG",
      "row":12,
      "col":3
    },
    "22": {
      "clue":"Oxidation von Eisen",
      "answer":"EISENOXID",
      "row":7,
      "col":16
    },
    "24": {
      "clue":"Synonym für \"Schottergrube\"",
      "answer":"KIESGRUBE",
      "row":8,
      "col":9
    },
    "26": {
      "clue":"Verbesserung, das Optimieren",
      "answer":"OPTIMIERUNG",
      "row":7,
      "col":6
    },
    "28": {
      "clue":"Synonym für \"Automobilproduzent\"",
      "answer":"FAHRZEUGBAUER",
      "row":2,
      "col":0
    }
  }
}

const Page = styled.div`
  padding: 2em;
`;

const Header = styled.h1`
  margin-bottom: 1em;
`;

const Commands = styled.div``;

const Command = styled.button`
  margin-right: 1em;
`;

const CrosswordMessageBlock = styled.div`
  margin: 2em 0 4em;
  display: flex;
  gap: 2em;
  max-height: 20em;
`;

const CrosswordWrapper = styled.div`
  max-width: 30em;

  /* and some fun making use of the defined class names */
  .crossword.correct {
    rect {
      stroke: rgb(100, 200, 100) !important;
    }
    svg > rect {
      fill: rgb(100, 200, 100) !important;
    }
    text {
      fill: rgb(100, 200, 100) !important;
    }
  }

  .clue.correct {
    ::before {
      content: '\u2713'; /* a.k.a. checkmark: ✓ */
      display: inline-block;
      text-decoration: none;
      color: rgb(100, 200, 100);
      margin-right: 0.25em;
    }

    text-decoration: line-through;
    color: rgb(130, 130, 130);
  }
`;

const CrosswordProviderWrapper = styled(CrosswordWrapper)`
  max-width: 150em;
  display: flex;
  gap: 1em;

  .direction {
    width: 24em;

    .header {
      margin-top: 0;
    }
  }

  .grid {
    width: 36em;
  }
}
`;

// in order to make this a more-comprehensive example, and to vet Crossword's
// features, we actually implement a fair amount...

function App() {

  var FileSaver = require('file-saver');

  // We don't really *do* anything with callbacks from the Crossword component,
  // but we can at least show that they are happening.  You would want to do

    const messagesRef = useRef<HTMLPreElement>(null);
    const [messages, setMessages] = useState<string[]>([]);
    var   [puzzleData, setPuzzleData] = useState<any>(data);
    const [labelData, setLabelData] = useState<WNLabel>(wn_labels[0]);
    const [loading, setLoading] = useState(false);
    const [selectedWordnet, setSelectedWordnet] = useState<WNOption | null>(wn_options[0]);
    const [selectedGrid, setSelectedGrid] = useState<GridOption | null>(grid_options[0]);
    const [selectedClue, setSelectedClue] = useState<ClueInfo>(exampleClue);

    const generatePuzzle = useCallback<React.MouseEventHandler>((event) => {
        crosswordProvider.current?.reset();
        setLoading(true);
        console.log('data', data, typeof(data), 'waitPleasePuzzle', waitPleasePuzzle, typeof(waitPleasePuzzle));
        data = waitPleasePuzzle;
        setPuzzleData(waitPleasePuzzle);
        crosswordProvider.current?.fillAllAnswers();
        crosswordProvider.current?.fillAllAnswers();
        crosswordProvider.current?.fillAllAnswers();
        console.log('trying to call Prolog with generatePuzzle',
                    selectedGrid, selectedGridSize.value,
                    selectedWordnet, selectedWN.value);
        fetch('/api/generatePuzzle?wordnet=' + selectedWN.value + '&gridSize=' + selectedGridSize.value, {  
            headers: {
                'Accept': 'application/json',
            }
        }).then((resp) => resp.json()) 
            .then((newData) => { setPuzzleData(newData);
                              crosswordProvider.current?.reset();
                              setLoading(false);
                              data = newData;
                            }
                 );
    }, [selectedWordnet, selectedGrid]);

    const printPuzzle = useCallback<React.MouseEventHandler>((event) => {
        setLoading(true);
        console.log('trying to call Prolog with printPuzzle', selectedWordnet, selectedWN.value);        
        fetch('/api/printPuzzle?wordnet=' + selectedWN.value, {  
            headers: {
                'Accept': 'application/pdf',
            }
        }).then((resp) => resp.arrayBuffer())
          .then((data) => { setLoading(false)
                            var blob = new Blob([data], {
                                type: "application/pdf"
                              });
                            FileSaver.saveAs(blob, "GN.pdf");
                            console.log('should be downloadable now', data);
                          }
               );
    }, [FileSaver,selectedWordnet]);
    
  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }
    const { scrollHeight } = messagesRef.current;
    messagesRef.current.scrollTo(0, scrollHeight);
  }, [messages]);


  // all the same functionality, but for the decomposed CrosswordProvider
  const crosswordProvider = useRef<CrosswordProviderImperative>(null);

  const focusProvider = useCallback<React.MouseEventHandler>((event) => {
    crosswordProvider.current?.focus();
  }, []);

    
  const gotoRover = useCallback<React.MouseEventHandler>((event) => {
      console.log('goto Rover', data, typeof(data), puzzleData, typeof(puzzleData), {selectedClue}, selectedDirection, selectedNumber, String(selectedDirection));
      puzzleData = data;
      const pp = puzzleData[selectedDirection];
      const answer = pp[selectedNumber]["answer"];
      const row = pp[selectedNumber]["row"];
      const col : number = Number( pp[selectedNumber]["col"] );

      console.log('answer word for Rover', answer);
      const roverAddress = 'https://weblicht.sfs.uni-tuebingen.de/rover/search/?word=';
      const pwnAddress = 'http://wordnetweb.princeton.edu/perl/webwn?s=';
      const dwdsAddress = 'https://www.dwds.de/wb/';
      
      const rover_url = roverAddress.concat(answer.toString());
      const pwn_url = pwnAddress.concat(answer.toString());
      const dwds_url = dwdsAddress.concat(answer.toString());
      
      if (selectedWN.value === 'germanet') {
          window.open(rover_url, '_blank');
      } else if (selectedWN.value === 'dwds') {
          window.open(dwds_url, '_blank');
      } else {
          window.open(pwn_url, '_blank');          
      }
  }, []);

  const fillAnswerForClue = useCallback<React.MouseEventHandler>((event) => {
      // get selected clue (dir, num)
      // get answer for selected clue
      console.log('fillAnswerForClue (incorrect state)', data, typeof(data), puzzleData, typeof(puzzleData), {selectedClue}, selectedDirection, selectedNumber, String(selectedDirection));
      puzzleData = data;
      const pp = puzzleData[selectedDirection];
      //const pp:any = data[selectedDirection];
      const answer = pp[selectedNumber]["answer"];
      const row = pp[selectedNumber]["row"];
      const col : number = Number( pp[selectedNumber]["col"] );

      console.log('puzzleData', data, puzzleData, pp, answer, row, col);

      for (const [i, character] of Object.entries(answer)) {
          if (selectedDirection === "across") {
              crosswordProvider.current?.setGuess(row, col+Number(i), String(character));
          } else
              crosswordProvider.current?.setGuess(row + Number(i), col, String(character));
      }
  }, []);

  const fillAllAnswersProvider = useCallback<React.MouseEventHandler>(
    (event) => {
      crosswordProvider.current?.fillAllAnswers();
    },
    []
  );

  const resetProvider = useCallback<React.MouseEventHandler>((event) => {
    crosswordProvider.current?.reset();
  }, []);

  // We don't really *do* anything with callbacks from the Crossword component,
  // but we can at least show that they are happening.  You would want to do
  // something more interesting than simply collecting them as messages.
  const messagesProviderRef = useRef<HTMLPreElement>(null);
  const [messagesProvider, setMessagesProvider] = useState<string[]>([]);

  const addMessageProvider = useCallback((message: string) => {
    setMessagesProvider((m) => m.concat(`${message}\n`));
  }, []);

  useEffect(() => {
    if (!messagesProviderRef.current) {
      return;
    }
    const { scrollHeight } = messagesProviderRef.current;
    messagesProviderRef.current.scrollTo(0, scrollHeight);
  }, [messagesProvider]);

  // onCorrect is called with the direction, number, and the correct answer.
  const onCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onCorrect']
  >(
    (direction, number, answer) => {
      addMessageProvider(`onCorrect: "${direction}", "${number}", "${answer}"`);
    },
    [addMessageProvider]
  );

  // onLoadedCorrect is called with an array of the already-correct answers,
  // each element itself is an array with the same values as in onCorrect: the
  // direction, number, and the correct answer.
  const onLoadedCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onLoadedCorrect']
  >(
    (answers) => {
      addMessageProvider(
        `onLoadedCorrect:\n${answers
          .map(
            ([direction, number, answer]) =>
              `    - "${direction}", "${number}", "${answer}"`
          )
          .join('\n')}`
      );
    },
    [addMessageProvider]
  );

  // onCrosswordCorrect is called with a truthy/falsy value.
  const onCrosswordCorrectProvider = useCallback<
    Required<CrosswordProviderProps>['onCrosswordCorrect']
  >(
    (isCorrect) => {
      addMessageProvider(`onCrosswordCorrect: ${JSON.stringify(isCorrect)}`);
    },
    [addMessageProvider]
  );

  // onCellChange is called with the row, column, and character.
  const onCellChangeProvider = useCallback<
    Required<CrosswordProviderProps>['onCellChange']
  >(
      (row, col, char) => {
      //console.log(`onCellChange: "${row}", "${col}", "${char}"`);          
      addMessageProvider(`onCellChange: "${row}", "${col}", "${char}"`);
    },
    [addMessageProvider]
  );

  // onClueSelected 
  const onClueSelectedProvider = useCallback<
    Required<CrosswordProviderProps>['onClueSelected']
  >(
      (dir, num) => {
          // need to activate button "Gib Lösungswort
          //
          const clue = { dir: dir, num:num };
          setSelectedClue( clue );
          selectedDirection = dir;
          selectedNumber = Number(num);
          console.log(`onClueSelected: "${dir}", "${num}"`, clue);
      addMessageProvider(`onClueSelected: "${dir}", "${num}"`);
    },
    [addMessageProvider]
  );
    
  return (
    <Page>
      
       <Header>GermaNet/DWDS/Princeton WordNet Kreuzworträtsel
          <img src = {EKUT_LOGO} alt="EKUT Logo" style={{width: 192, height: 192, float: "right"}} />
          <img src = {TEXTPLUS_LOGO} alt="Text+ Logo" style={{width: 128, height: 128, float: "right"}} />
      </Header>

      <table>
       <tbody>
        <tr>
        <td>
          Diese App generiert automatisch Kreuzworträtsel unter Verwendung von:
        </td>
        <td>
        <Select
          value={selectedWordnet}
          onChange={(newValue) => {
            console.log('onChange', newValue, selectedWN)
            setSelectedWordnet(newValue);
            selectedWN = newValue;
            console.log('onChange afterwards', selectedWordnet, selectedWN, selectedWN.value)
            if (selectedWN.value === 'germanet') {
                setLabelData( wn_labels[0] );
                roverOrPWN = rover_pwn_options[0].label;
            } else if (selectedWN.value === 'dwds') {
                setLabelData( wn_labels[1] );
                roverOrPWN = rover_pwn_options[1].label;
            } else {
                setLabelData( wn_labels[2] );
                roverOrPWN = rover_pwn_options[2].label;
            }
          }}
          options={wn_options}
          theme={(theme) => ({
              ...theme,
              borderRadius: 32,
              display: 'block',
              width:100,
              height: 10,
    })}
      />
          </td>
        <td>
        <Select
          value={selectedGrid}
          onChange={(newValue) => {
            console.log('onChange', newValue, selectedGrid)
            setSelectedGrid(newValue);
            selectedGridSize = newValue;
              console.log('onChange afterwards', selectedGrid, selectedGridSize, selectedGridSize.value)
          }}
          options={grid_options}
          theme={(theme) => ({
      ...theme,
              borderRadius: 32,
              width:100,
    })}
      />
             </td>          
          </tr>
          <tr>
          
          <td>
          </td>
          
          <td>
          </td>
          
          </tr>
          <tr>
          <td>
      <Commands>
        <Command onClick={generatePuzzle}>Neues Rätsel !</Command>
        <Command onClick={printPuzzle}>Rätsel als PDF</Command>
        <Command onClick={focusProvider}>Fokus</Command>
        <Command onClick={fillAnswerForClue}>Gib Lösungswort</Command>
        <Command onClick={gotoRover}>{roverOrPWN}</Command>
        <Command onClick={fillAllAnswersProvider}>Gib alle Lösungen</Command>
        <Command onClick={resetProvider}>Reset</Command>
      </Commands>
          </td>
          <td>
      <ThreeCircles
        height="64"
        width="64"
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#29a900"
        innerCircleColor="#e77e00"
        middleCircleColor="#00a8cc"
          />
          </td>
          <td>
          </td>
          
          </tr>
                    </tbody>
          </table>

      <CrosswordMessageBlock>
        <CrosswordProviderWrapper>
          <CrosswordProvider
            ref={crosswordProvider}
            data={puzzleData} 
            storageKey="someStorageKey"
            onCorrect={onCorrectProvider}
            onLoadedCorrect={onLoadedCorrectProvider}
            onCrosswordCorrect={onCrosswordCorrectProvider}
            onCellChange={onCellChangeProvider}
            onClueSelected={onClueSelectedProvider}
          >
          <DirectionClues direction="across" label={labelData.across} />
          <CrosswordGrid />
          <DirectionClues direction="down" label={labelData.down} />
          </CrosswordProvider>
        </CrosswordProviderWrapper>
      </CrosswordMessageBlock>
    </Page>
  );
}

export default App;
